'use client'

import { useState } from 'react'
import { Anchor } from '../../../components/anchor/anchor'
import styles from './home-page-seo-links.module.css'
import clsx from 'clsx'
import { useMediaQuery } from '@rentpath/react-hooks'
import { Heading } from '../../../components/heading/heading'

export const SMALL_LIMIT = 6
export const LARGE_LIMIT = 18

export type SectionData = {
  subtitle: string
  links: [string, string][]
}

export interface SeoLinksProps {
  section: SectionData
}

export function SeoLinks({ section }: SeoLinksProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const isTabletOrAbove = useMediaQuery('(min-width: 768px)')

  if (
    !section ||
    !section.subtitle ||
    !Array.isArray(section.links) ||
    section.links.length === 0
  )
    return null

  const limit = isTabletOrAbove ? LARGE_LIMIT : SMALL_LIMIT
  const shouldRenderSeeMoreOrLessButton = section.links.length > limit

  return (
    <>
      <Heading as="h3" className={styles.subtitle}>
        {section.subtitle}
      </Heading>
      <ul
        className={clsx(
          styles.list,
          !shouldRenderSeeMoreOrLessButton && styles.withoutButton
        )}
      >
        {section.links.map((link, index) => {
          const isItemHidden = index > limit - 1
          const href = link[0]
          const text = link[1]

          if (!href || !text) return null

          return (
            <li
              className={clsx(
                styles.listItem,
                !isExpanded && isItemHidden ? styles.hidden : styles.visible
              )}
              key={text + href}
            >
              <Anchor className={styles.link} href={href}>
                {text}
              </Anchor>
            </li>
          )
        })}
      </ul>
      {shouldRenderSeeMoreOrLessButton && (
        <Anchor
          as="button"
          className={styles.viewMoreOrLessButton}
          data-tag_item={`show_${isExpanded ? 'more' : 'less'}_link`}
          onClick={() => {
            setIsExpanded((prev) => !prev)
          }}
        >
          {`View ${isExpanded ? 'Less -' : 'More +'}`}
        </Anchor>
      )}
    </>
  )
}
