import logo from '../static/images/app-banner-logo.svg'

type OneLinkParamValue = string | number | boolean | undefined
export type OneLinkParams = Record<string, OneLinkParamValue>

export function getSmartOneLink(deepLinkParams: OneLinkParams = {}) {
  const oneLinkURL = 'https://rent.onelink.me/ZiR9/34e9ed45'

  let smartURL = `${oneLinkURL}?af_js_web=true&af_ss_ver=2`

  Object.keys(deepLinkParams).forEach((key) => {
    const value = deepLinkParams[key]

    if (value) {
      smartURL += `&${key}=${value}`
    }
  })

  return smartURL
}

export const APP_NAME = 'Rent.™'

export const RATING_COUNT = '200k'

export const APP_STORE_URL =
  'https://apps.apple.com/us/app/rent-com-apartments-homes/id388038507'
export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.rent&hl=en_US&gl=US'

export const appBannerData = {
  getSmartOneLink,
  logo,
  logoAltText: 'Rent. logo',
}
